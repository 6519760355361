import Book from "../../assets/book.svg";
import Bell from "../../assets/bell.svg";
import Chat from "../../assets/chat.svg";
import Loan from "../../assets/loan.svg";

export const Items = [
  {
    title: "Lone wolf, Low sales : ",
    desc: "IG vendors, Whatsapp vendor and other Social media vendors",
  },
  {
    title: "Road side, Save lives : ",
    desc: "Vulcanizer, POS agents, Aboki...",
  },
  {
    title: "Neighbourhood got it good :  ",
    desc: "Shops, supermarkets, Retail units...",
  },
  {
    title: "Supply chains and Cooperatives : ",
    desc: "Farmers, Manufacturers, Distributors...",
  },
];

export const Items2 = [
  {
    title: "Customers 24/7",
    desc: "Purchase intent alerts when customers search to help you get to the custoemr before anybody else",
    icon: Book,
  },
  {
    title: "Be in Control ",
    desc: "Switch off without switching off when you need to take a break",
    icon: Loan,
  },
  {
    title: "Focus on sales",
    desc: "While our delivery partners do the work of delivery free of charge",
    icon: Bell,
  },
  {
    title: "0.2 Seconds",
    desc: "Thats how much time it takes for you to receive payments. Withdraw straight to your bank account",
    icon: Book,
  },
  {
    title: "More Power",
    desc: "Keyword powered dashboard lets you beat the competition by knowing what they are up to",
    icon: Chat,
  },
];
