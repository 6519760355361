import B1 from "../../assets/bl1.png";
import B2 from "../../assets/bl2.png";
import B3 from "../../assets/bl3.png";
import B4 from "../../assets/b4.png";
import B5 from "../../assets/bl5.png";
import B6 from "../../assets/bl6.png";
import B10 from "../../assets/bl10.png";
import B11 from "../../assets/bl11.png";
import B12 from "../../assets/bl12.png";
const investment = 'https://miro.medium.com/max/1050/1*ArNC91IPVeg_XFCbvcfwvQ.jpeg'
const business = 'https://miro.medium.com/max/1050/1*Wou0HsSwcYU3iCqHT5Lc0w.jpeg'
const unemployment = 'https://miro.medium.com/max/1050/1*m32cqRjDDlSRPJ7ZjJkueQ.jpeg'
const funding = `https://miro.medium.com/max/1050/1*A7hN54ZO-B0LHaBJnaJLZA.jpeg`
const multichannel = `https://miro.medium.com/max/960/0*DiHdhkODQQPD_BIn`
const ominichannel = `https://miro.medium.com/max/450/1*qSRqpZsLTAkp2L7cSk-xeA.jpeg`
const loan = 'https://miro.medium.com/max/918/1*fbszGLJ4srFlI_yky_RBeg.jpeg'
const growBusiness = `https://miro.medium.com/max/1050/1*yKkMG4PIlZvf8-x5f-RIzg.jpeg`
const ecommerce = `https://miro.medium.com/max/1050/1*T7RTFfsd7xwoMYcT-kPvAA.png`

const cards =[ 
    {
    img: ecommerce,
    header: "What’s the Difference Between Marketplace and E-Commerce Platforms?",
    subtext:
      "If you are already thinking of being an online vendor, you may begin to wonder about what your best option is..",
    timing: "3 Min Read",
    link:
      "https://thespottrapp.medium.com/whats-the-difference-between-marketplace-and-e-commerce-platforms-73abfa3b9c53",
  },
    {
    img: growBusiness,
    header: "How to Grow Your Online Business",
    subtext:
      "Being an online entrepreneur means you have to always be ahead of your competitors. Just like any other..",
    timing: "4 Min Read",
    link:
      "https://thespottrapp.medium.com/how-to-grow-your-online-business-bd4df96343ce",
  },
    {
    img: loan,
    header: "How to Access Loans in Nigeria",
    subtext:
      "A few people might frown at the thought of a loan. You constantly hear people say “debt dey fear me!...",
    timing: "3 Min Read",
    link:
      "https://thespottrapp.medium.com/how-to-access-loans-in-nigeria-6a39af9f6c5f",
  },
    {
    img: ominichannel,
    header: "Everything you Should Know About Omnichannel Retail",
    subtext:
      "Omnichannel retail has become a buzzword on the internet. It used to be about multichannel but now...",
    timing: "6 Min Read",
    link:
      "https://thespottrapp.medium.com/everything-you-should-know-about-omnichannel-retail-3b86278e5b26",
  },
    {
    img: multichannel,
    header: "The Difference Between Multichannel & Omnichannel Retail",
    subtext:
      "Omnichannel and multichannel can be a little confusing if you are new to the online retail business...",
    timing: "4 Min Read",
    link:
      "https://thespottrapp.medium.com/the-difference-between-multichannel-omnichannel-retail-e3275572a41a",
  },
    {
    img: funding,
    header: "7 Ways to Finance Your Business Idea",
    subtext:
      "You have a feasible idea, you have concluded all analysis to help you validate this busin..",
    timing: "4 Min Read",
    link:
      "https://thespottrapp.medium.com/7-ways-to-finance-your-business-idea-bd92ec04113a",
  },
    {
    img: unemployment,
    header: "6 Ways to Cope With Unemployment",
    subtext:
      "Chris has been without a job for the past 12months. He has just completed his National Youth Corp. Chris used to be v..",
    timing: "4 Min Read",
    link:
      "https://thespottrapp.medium.com/6-ways-to-cope-with-unemployment-55c525823d7",
  },
    {
    img: business,
    header: "How to Start a Retail Online Business on a Budget",
    subtext:
      "lack of or little capital shouldn’t stop you. You can start a lean retail business in these..",
    timing: "5 Min Read",
    link:
      "https://thespottrapp.medium.com/how-to-start-a-retail-online-business-on-a-budget-8def010946c7",
  },
    {
    img: investment,
    header: "3 Ways to Invest in Small Businesses",
    subtext:
      "That’s exactly what investing in a small business venture can do for you. If it becomes successful, you stand a chance of getting higher...",
    timing: "3 Min Read",
    link:
      "https://thespottrapp.medium.com/3-ways-to-invest-in-small-businesses-e87b41ccacd4",
  },
    {
    img: B1,
    header: "Using AI and Cryptocurrency, SpottR will change global retail commerce… forever",
    subtext:
      "SpottR is an eCommerce platform that uses artificial intelligence to help people find items they need in less than 2 minutes. Bridging the gap between...",
    timing: "2 Min Read",
    link:
      "https://thespottrapp.medium.com/using-ai-and-cryptocurrency-spottr-will-change-global-retail-commerce-forever-by-benjamin-dada-e1fbbe8013ab",
  },
    {
    img: B2,
    header: "How to Solve your money problems with Spottr.",
    subtext:
        "It’s a new year and as usual, a lot of people hold new year resolutions. Many will achieve theirs, others won’t but, when you have a partner that cares, the...",
    timing: "5 Min Read",
    link:
        "https://thespottrapp.medium.com/how-to-solve-your-money-problems-with-spottr-6bb7a88dab81",
    },
   {
    img: B3,
    header: "Introducing Our Utility Schemes",
    subtext:
      "Hiya SpottR, I come bearing info. Today I’d show you how to do more with CLIQ tokens. CLIQ token allows us to solve Africa’s non-consumption problem by offering...",
    timing: "5 Min Read",
    link:
      "https://thespottrapp.medium.com/introducing-our-utility-schemes-5f2341e8d664",
  },
   {
    img: B4,
    header: "WHY SPOTTR",
    subtext:
      "In many ways than one (1), the SpottR app helps you do more and in this article, I’ll show you how; 1. First, the SpottR app helps you to discover goods...",
    timing: "3 Min Read",
    link: "https://thespottrapp.medium.com/-3880e60c75a3",
  },
  {
    img: B5,
    header: "High Yield Businesses to do in 2021.",
    subtext:
      "Because certain individuals don’t have the technical know-how or strategies to start a business, we’ve done the hard work by researching and compiling high yield...",
    timing: "6 Min Read",
    link:
      "https://thespottrapp.medium.com/high-yield-businesses-to-do-in-2021-d07c0529c6fa",
  },
  {
    img: B6,
    header: "What Most Nigerians Want But Can’t Afford.",
    subtext:
      "Everyone has basic needs that are important for their well-being. Beyond the basic necessities; food, shelter, and clothing, to get by life, there are secondary...",
    timing: "8 Min Read",
    link:
      "https://thespottrapp.medium.com/what-most-nigerians-want-but-cant-afford-c2704d52e525",
  },
  {
    img: B4,
    header: "30 days to the end of CLIQ Presale.",
    subtext:
      "Truly, Opportunities come and go, but not without giving you a window to grab them or jump in, if it’s one too big to grab. Information and preparedness are...",
    timing: "2 Min Read",
    link:
      "https://thespottrapp.medium.com/30-days-to-the-end-of-cliq-presale-ad288d944425",
  },
  {
    img: B3,
    header: "CLIQTOKEN MINI BOUNTY",
    subtext:
      "Did you miss out on UNI? don’t miss out on CLIQ. A new opportunity to earn the native token of the SpottR ecosystem – The CLIQ token. Powering the SpottR...",
    timing: "2 Min Read",
    link: "https://thespottrapp.medium.com/cliqtoken-mini-bounty-e90640f2ddce",
  },
  {
    img: B10,
    header: "The SpottR Campus API Challenge",
    subtext:
      "Do you have what it takes to stand out in the SpottR Campus API Challenge? then you have the opportunity to win a piece of the 18million Naira prize pool...",
    timing: "1 Min Read",
    link:
      "https://thespottrapp.medium.com/the-spottr-campus-api-challenge-b108b060838b",
  },
  {
    img: B11,
    header: "How To Upload an Opportunity / Service on Spottr.",
    subtext:
      "- Click on the " +
      " in between the App tabs stationed at the bottom of the App., - Tap on the Camera Icon to Upload Opportunity Image., - Select the source of...",
    timing: "1 Min Read",
    link:
      "https://thespottrapp.medium.com/how-to-upload-an-opportunity-service-5fb6e42f3fbd",
  },
   {
    img: B12,
    header: "How to Fund Your Wallet on Spottr.",
    subtext:
      'In order to fund your SpottR Wallet you must do the following; - Add a card from the "Manage Payments" in the User Profile. - To add...',
    timing: "2 Min Read",
    link:
      "https://thespottrapp.medium.com/how-to-fund-your-wallet-7c3db46bdd55",
  }
]

  export default cards