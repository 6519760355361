import React from "react";

import PrivacyPage from "../components/Privacy";

function Privacy() {
  return (
    <>
      <PrivacyPage />
    </>
  );
}

export default Privacy;
